<template>
  <div>
    <b-card
      class="mt-3 buy"
      header="How will you split your trade between each of the client portfolios?"
      inline
    >
      <b-card
        class="buy"
        v-for="(element, portfolio, index) in form_porfolioList"
        :key="`${index}+${portfolio}`"
        :header="Object.keys(form_porfolioList)[index]"
        :id="index"
        v-bind:class="{ active: form_counter[index] }"
      >
        <b-form inline v-for="(value, keyy, i) in element" :key="[keyy] + [i]">
          <b-form-checkbox
            class="mb-2 mr-sm-2 mb-sm-0"
            @change="select(event)"
            v-bind:value="`${portfolio}.${keyy}`"
            >{{ keyy }}</b-form-checkbox
          >
          <b-input
            v-if="activePortofolios.includes(keyy)"
            v-bind:key="i + 'x'"
            v-model="element[keyy]"
            class="mb-2 mr-sm-2 mb-sm-0"
            @update="updateStore(event)"
          ></b-input>
        </b-form>
      </b-card>
    </b-card>
  </div>
</template>

<script>
export default {
  name: "WizardComponent4",

  data() {
    return {
      val: 100,
      objCounter: {}, //this Object = {cliente1:"number of active checkbox", cliente2:"number of activ..."...} Cuando se activa/desactiva un checkbox se suma/resta
      lastClient: "", //Cuando se activa/desactiva el checkbox se bindea el "lastClient",esto dispara el watcher que bindea los valores a mi objeto de portfolios en base al counter de cada cliente
      activePortofolios: [], //Cuando se activa/desactiva el checkbox se agrega el nombre del portfolio. Se usa para el v-if de los input.Fue necesario para lograr la reactividad deseada ya que los cambios en los valores de objetos no disparan un "re-render"
      event: {},
    };
  },
  computed: {
    form_counter() {
      return this.$store.state.form_counter.map((a) => a !== 100);
    },
    form_porfolioList() {
      return this.$store.state.form_porfolioList;
    },
    sum() {
      let ar = Object.values(this.form_porfolioList);
      let sumarray = ar.map(function (object) {
        return Object.keys(object).reduce(function (sum, key) {
          return sum + object[key];
        }, 0);
      });
      return sumarray;
    },
    portfolioClean() {
      let list = this.$store.state.form_porfolioList;
      let list2 = Object.entries(list).map(([key, value]) => [
        key,
        Object.entries(value).reduce(
          (a, [k, v]) => (v ? ((a[k] = v), a) : a),
          {}
        ),
      ]);

      return Object.fromEntries(list2);
    },
  },

  methods: {
    select() {
      //Por aca pasa toda la complejidad del codigo, Esto se dispara cuando activo/desactivo el checkbox

      let Cl_PORTF = event.target.value.split("."); //cada checkbox tiene asignado un valor que indica su cliente y nombre de portfolio pero viene "cliente.portfolio"
      let client = Cl_PORTF[0]; //nombro variables locales para mayor limpieza en el codigo
      let portfolio = Cl_PORTF[1];
      if (event.target.checked) {
        //si activo el checkbox -->
        this.form_porfolioList[client][portfolio] = true; //  se asigna un valor (importante que sea distinto de cualquier falsy)
        this.activePortofolios.push(portfolio); //            se pushea a un array para disparar el v-if del input
        this.objCounter[client] //                            se suma el counter o se crea si no existe
          ? this.objCounter[client]++
          : (this.objCounter[client] = 1);
      } else {
        //si desactivo -->
        this.objCounter[client]--; //se resta 1 a  el counter del cliente
        this.form_porfolioList[client][portfolio] = null; //se asigna valor falsy(ver el watcher de lastclient )
        this.activePortofolios = this.activePortofolios.filter(
          // se saca del array , desaparece el input
          (item) => item !== portfolio
        );
      }

      this.lastClient = client; //Dispara el watcher
    },
    updateStore() {
      this.$store.commit(
        "SET_FORM_PORTFOLIOLIST_UPDATED",
        this.form_porfolioList
      );
    },
  },
  watch: {
    lastClient: {
      immediate: false,

      handler() {
        let portion = Math.floor(100 / this.objCounter[this.lastClient]) || 100;

        let client = this.form_porfolioList[this.lastClient];
        if (this.lastClient) {
          //se asigna la misma "porcion" a los portfolios que tengan valores Truly , y 0 a las demas.
          this.form_porfolioList[this.lastClient] = Object.entries(
            client
          ).reduce(
            (a, [k, v]) => (v ? ((a[k] = portion), a) : (a[k] = null), a),
            {}
          );
          this.updateStore();
        }

        this.lastClient = ""; //Se establece el ultimo cliente como "" para poder interactuar seguido con el mismo cliente
      },
    },
  },
};
</script>

<style scoped>
.card-header {
  font-weight: 500;

  font-size: 1.4rem;
}
.buy > .card-header {
  background-color: var(--buy-color);
  color: aliceblue;
}
.active {
  background-color: #f5282828;
}
</style>
